.data-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: .6em;
    padding: 0em 2em 0 2em;
    max-width: 93em;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

table {
    border-spacing: 0;
    border: 2px solid #8f8f8f;
    background: white;
    margin: 1em;
    text-align: left;
    border-radius: 4px;
    font-size: 1.2em;
}

th {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    white-space: nowrap;
    background-color: #8f8f8f;
    color: #fff;
    border: none;
}

th:hover {
    background-color: #777;
}

td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    white-space: nowrap;
    border-color: #aaa;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

.sortIndicator {
    display: inline-block;
    width: 20px;
    padding-left: 2px;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

.headerStyle {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}

tr {
    background: #fff;
}

tr:nth-of-type(even) {
    background: #eee;
}

tr:hover {
    background: #ace;
}

tr:hover a {
    background: #ace;
    border: 1px solid #888;
}

.tdRight {
    text-align: right;
}

.third-togo.header {
    cursor: none
}

.no-results {
    background-color: #ecc;
    text-align: center;
    font-size: 1.5em;
    align-self: center;
    padding: 8px 20px;
    border-radius: 8px;
    font-weight: bold;
    color: #555;
}

@media screen and (max-width: 1050px) {
    .first-togo {
        display: none;
    }
    .company-title {
        max-width: 10em;
        overflow: hidden;
    }
    .title {
        white-space: nowrap;
        position: relative;
        overflow: hidden;    /* Required to make ellipsis work */
        text-overflow: ellipsis;

        left: 0%;
        min-width: 10em;
        width: 100%;

        -webkit-transition: left 3s, width 3s;
        -moz-transition: left 3s, width 3s;
        transition: left 3s, width 3s;
    }

    tr:hover .company-title .long {
        left: -60%;
        width: 210%;
    }
}

@media screen and (max-width: 900px) {
    .second-togo {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .third-togo {
        display: none;
    }

    .table {
        font-size: .5em;
    }
}

@media screen and (max-width: 550px) {
    .fourth-togo {
        display: none;
    }
}

@media screen and (max-width: 350px) {
    .fourth-togo {
        display: none;
    }

    .shorten-name {
        white-space: pre-wrap;
        padding-top: 2em;
    }

    tr:hover .company-title .long {
        left: -100%;
        width: 210%;
    }
}
