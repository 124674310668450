.App {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .App {
    font-size: .8em;
  }
}

@media screen and (max-width: 350px) {
  .App {
    font-size: .6em;
  }

  .App-header-group .form-group {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 1em;
  }
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: initial;
  align-items: center;
  justify-content: center;
  color: #3f3f3f;
  border-bottom: 2px solid #e8e8e8;
  width: 100%;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select:none;
  user-select:none;
  -o-user-select:none;
}

.App-header-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 90vw;
}

.App-header h1 {
  font-family: courier;
  padding: 0px 20px;
}

.statText {
  margin: 14px 0px 0px 0px;
  width: 1010px;
  margin: 10px 40px 0px 40px;
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #ccc;
  background-color: #ccc;
  color: #000;
}

.footer {
  color: #555;
  font-size: 12px;
  text-align: center;
  margin-bottom: 30px;
}

.footer a {
  text-decoration: none;
  color: #555;
}

.footer a:hover {
  text-decoration: underline;
}

h1 {
  margin: 4px;
  white-space: nowrap;
}

.form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

form.search {
  margin: 10px 20px;
}

form.search input {
  width: 12em;
  height: 2.3em;
  border: 1px solid #888;
  padding: 2px 8px;
  border-radius: 8px;
  
  -webkit-appearance: none
}

a.sources {
  text-decoration: none;
  background-color: #ccc;
  color: #000;
  padding: 2px 4px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-right: 6px;
}

a.sources:hover {
  background-color: #9df;
}

span.verified-source {
  text-decoration: none;
  background-color: #cdc;
  color: #000;
  padding: 2px 4px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-right: 6px;
}

span.verified-source:hover {
  border-color: #cdc;
}


.submit-button {
  margin: auto 1em;
  background-color: #ace;
  border: 1px solid #ace;
  color: #333;
  font-size: 11px;
  font-weight: bold;
  border-radius: 8px;
  width: 120px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 8px 0px;
}

.submit-button:hover {
  cursor: pointer;
  border: 1px solid #888;
  background-color: #9df;
}

.producthunt-badge {
  width: 250px;
  height: 54px;
  margin-bottom: 6px;
}

@media screen and (max-width: 1050px) {
  .statText {
    width: 70.5em
  }
}

@media screen and (max-width: 900px) {
  .statText {
    width: 62em
  }
}

@media screen and (max-width: 800px) {
  .statText {
    width: 40em
  }
}

@media screen and (max-width: 800px) {
  .statText {
    font-size: .8em;
    width: 42.5em;
  }
}

@media screen and (max-width: 550px) {
  .statText {
    width: 32.5em;
  }
}

@media screen and (max-width: 350px) {
  .statText {
    width: 82%;
  }
}